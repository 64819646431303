// Installs.js
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "./styles";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import api from "../../services/api";
import moment from "moment";

function Installs() {
  const client_id = new URLSearchParams(useLocation().search).get("client_id");
  const store_code = new URLSearchParams(useLocation().search).get(
    "store_code"
  );

  const navigate = useNavigate();

  const [ESLs, setESLs] = useState();

  useEffect(() => {
    api.get(`/logs/install`).then((response) => {
      setESLs(response.data);
    });
  }, []);

  return (
    <Container>
      <h3>Instalações e Desinstalações</h3>

      <MDBTable align="start" hover>
        <MDBTableHead>
          <tr>
            <th>Mac</th>
            <th>Ação</th>
            <th>SGLN</th>
            <th>Data</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {ESLs ? (
            ESLs.map((esl) =>
              esl.actions.map((action) => (
                <tr
                  className="table-light"
                  onClick={() => navigate(`/details/${esl.mac}`)}
                >
                  <td>{action.mac}</td>
                  {action.action === "install" && <td>Instalada</td>}
                  {action.action === "uninstall" && <td>Desinstalada</td>}
                  <td>{action.sgln}</td>
                  <td>
                    {moment
                      .utc(action.timestamp)
                      .format("HH:mm:ss - DD/MM/YYYY")}
                  </td>
                </tr>
              ))
            )
          ) : (
            <tr>
              <td colSpan="6">Nenhuma instalação no sistema</td>
            </tr>
          )}
        </MDBTableBody>
      </MDBTable>
    </Container>
  );
}

export default Installs;

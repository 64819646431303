import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1% 5%;

  h3,
  th {
    font-weight: bold;
  }
`;

export const Title = styled.div`
  flex-direction: column;
  align-items: flex-start;
  padding: 1% 5%;

  h3 {
    font-weight: bold;
  }
`;

export const SubmitButton = styled.button`
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 5%;
  margin-bottom: 5%;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

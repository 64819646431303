import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  position: relative;

  select {
    padding: 0.65rem;
    border: 1px;
    border-radius: 0.25rem;
    font-size: 1rem;
    background-color: #ebebeb;
  }
`;

import React, { useState } from "react";
import { UploadContainer, UploadInput, UploadButton } from "./styles";

const Uploader = ({ onFileSelect }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    onFileSelect(file); // Chama a função onFileSelect e passa o arquivo como argumento
  };

  const handleButtonClick = () => {
    document.getElementById("uploadInput").click();
  };

  return (
    <UploadContainer>
      <UploadInput type="file" onChange={handleFileChange} id="uploadInput" />
      <UploadButton onClick={handleButtonClick}>
        {selectedFile ? selectedFile.name : "Escolher Arquivo"}
      </UploadButton>
    </UploadContainer>
  );
};

export default Uploader;

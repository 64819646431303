import React, { useEffect, useState } from "react";
import { Container, Infos } from "./styles";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

import api from "../../services/api";
import Input from "../../components/Input/Input";
import ButtonGrey from "../../components/ButtonGrey/ButtonGrey";
import ButtonGreen from "../../components/ButtonGreen/ButtonGreen";

function SearchLocalization() {
  const [products, setProducts] = useState([]);
  const [sgln, setSgln] = useState("");

  function handleSearchSGLN() {
    api
      .get(`/search/product/${sgln}`)
      .then((response) => {
        setProducts(response.data);
      })
      .catch(() => {
        setProducts([
          { product_gtin: "Nenhum produto encontrada para a localização" },
        ]);
      });
  }

  return (
    <Container>
      <h3>Localização</h3>
      <Infos>
        <MDBRow style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <MDBCol md="2" className="d-flex align-items-center">
            <h5>Buscar localização: </h5>
          </MDBCol>
          <MDBCol md="7">
            <Input
              type="text"
              autoComplete="gtin"
              name="gtin"
              placeholder="Digite o SLGN da posição"
              value={sgln}
              onChange={(e) => setSgln(e.target.value)}
            />
          </MDBCol>
          <MDBCol md="3">
            <ButtonGreen
              type="submit"
              onClick={handleSearchSGLN}
              label="Pesquisar"
            />
          </MDBCol>
        </MDBRow>

        <MDBTable align="start" hover>
          <MDBTableHead>
            <tr>
              <th scope="col">Mac</th>
              <th scope="col">GTIN</th>
              <th scope="col">Titulo</th>
              <th scope="col">Avaliação</th>
              <th scope="col">Preço</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {products.length === 0 ? (
              <tr>
                <td colSpan="5">
                  <p className="text-center" style={{ marginTop: "1%" }}>
                    Nenhum produto encontrado.
                  </p>
                </td>
              </tr>
            ) : (
              products.map((product) => (
                <tr key={product.product_gtin} className="table-light">
                  <td>{product.esl_mac}</td>
                  <td>{product.product_gtin}</td>
                  <td>{product.product_title}</td>
                  <td>{product.product_rating}</td>
                  <td>{product.product_price}</td>
                </tr>
              ))
            )}
          </MDBTableBody>
        </MDBTable>
      </Infos>
    </Container>
  );
}

export default SearchLocalization;

import styled from "styled-components";

export const Container = styled.button`
  background-color: #ebebeb;
  border-radius: 10px;
  height: 45px;
  width: 100%;
  color: grey;
  font-size: 18px;
  border: none;
  cursor: pointer;
`;

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2% 5%;
  width: 100%;

  h3 {
    font-weight: bold;
  }
`;

export const Infos = styled.div`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  h4 {
    margin-top: 1%;
  }

  h5,
  h6 {
    margin-top: 1%;
  }
`;

import React, { useState } from "react";
import { Container } from "./styles";

function ButtonSequence({ numbers, onButtonClick }) {
  const [activeButton, setActiveButton] = useState("1");

  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
    if (typeof onButtonClick === "function") {
      onButtonClick(buttonNumber);
    }
  };

  return (
    <div className="button-sequence">
      {numbers.map((number) => (
        <Container
          key={number}
          active={activeButton === number}
          onClick={() => handleButtonClick(number)}
        >
          {number}
        </Container>
      ))}
    </div>
  );
}

export default ButtonSequence;

import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import { PDFDocument, rgb } from "pdf-lib";
import qrCode from "qrcode"; // Importe a biblioteca qrcode

import { Container, SubmitButton, Title } from "./styles";
import {
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import api from "../../services/api";

const PrintQRCode = () => {
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [availableCodes, setAvailableCodes] = useState([]);

  // Carregar storeCode do localStorage
  const store_code = localStorage.getItem("storeCode");

  useEffect(() => {
    api
      .get(`/physicalOrganization?store_code=${store_code}`)
      .then((response) => {
        setAvailableCodes(response.data);
      });
  }, [store_code]);

  const handleCheckboxChange = (code) => {
    if (selectedCodes.some((c) => c.sgln === code.sgln)) {
      setSelectedCodes(selectedCodes.filter((c) => c.sgln !== code.sgln));
    } else {
      setSelectedCodes([...selectedCodes, code]);
    }
  };

  const handleSelectAll = () => {
    setSelectedCodes(availableCodes);
  };

  const handleDeselectAll = () => {
    setSelectedCodes([]);
  };

  const handleGeneratePDF = async () => {
    if (selectedCodes.length > 0) {
      const pdfContent = await generatePDF(selectedCodes);
      const blob = new Blob([pdfContent], { type: "application/pdf" });
      saveAs(blob, "qrcodes.pdf");
    }
  };

  const generatePDF = async (codes) => {
    const pdfDoc = await PDFDocument.create();
    const qrSize = 150;
    const qrSpacingX = 50;
    const qrSpacingY = 50;
    const codesPerRow = 2; // 2 códigos por linha
    const maxRowsPerPage = 4; // 4 linhas por página
    const maxCodesPerPage = codesPerRow * maxRowsPerPage; // Total de códigos por página

    let x = (800 - (codesPerRow * qrSize + (codesPerRow - 1) * qrSpacingX)) / 2; // Centraliza horizontalmente
    let y = 1000; // Ajuste a altura para posicionar corretamente

    let page = pdfDoc.addPage([800, 1100]); // Tamanho da página A4 (ajuste conforme necessário)
    let qrCodesOnPage = 0;

    for (const code of codes) {
      if (qrCodesOnPage >= maxCodesPerPage) {
        page = pdfDoc.addPage([800, 1100]); // Nova página A4
        x = (800 - (codesPerRow * qrSize + (codesPerRow - 1) * qrSpacingX)) / 2; // Centraliza horizontalmente
        y = 1000; // Ajuste a altura para posicionar corretamente
        qrCodesOnPage = 0;
      }

      const cleanCode = code.sgln.trim(); // Remove espaços em branco extras
      const qrDataURL = await generateQRCodeImage(cleanCode);
      const qrImage = await pdfDoc.embedPng(qrDataURL);

      page.drawImage(qrImage, {
        x: x,
        y: y - qrSize,
        width: qrSize,
        height: qrSize,
      });

      const textWidth = computeTextWidth(cleanCode, 12);
      page.drawText(cleanCode, {
        x: x + qrSize / 2 - textWidth / 2,
        y: y - qrSize - 15,
        size: 12,
        color: rgb(0, 0, 0),
      });

      qrCodesOnPage++;
      x += qrSize + qrSpacingX;

      if (qrCodesOnPage % codesPerRow === 0) {
        y -= qrSize + qrSpacingY;
        x = (800 - (codesPerRow * qrSize + (codesPerRow - 1) * qrSpacingX)) / 2; // Centraliza horizontalmente
      }
    }

    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
  };

  const generateQRCodeImage = (code) => {
    return new Promise((resolve) => {
      qrCode.toDataURL(code, { width: 150 }, (error, url) => {
        resolve(url);
      });
    });
  };

  function computeTextWidth(text, fontSize) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `${fontSize}px Arial`;
    return context.measureText(text).width;
  }

  return (
    <>
      <ToastContainer />
      <Title className="text-start">
        <h3>Imprimir QRCodes de GLNs</h3>
      </Title>

      <Container>
        <MDBTable align="start" hover>
          <MDBTableHead>
            <tr>
              <th>Selecionar</th>
              <th>SGLN</th>
              <th>Seção</th>
              <th>Prateleira</th>
              <th>Nível</th>
              <th>Slot</th>
              <th>Face</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {availableCodes?.map((gln) => (
              <React.Fragment key={gln}>
                <tr style={{ cursor: "pointer" }}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedCodes.some((c) => c.sgln === gln.sgln)}
                      onChange={() => handleCheckboxChange(gln)}
                    />
                  </td>
                  <td>{gln.sgln}</td>
                  <td>{gln.section}</td>
                  <td>{gln.shelf}</td>
                  <td>{gln.level}</td>
                  <td>{gln.slot}</td>
                  <td>{gln.face}</td>
                </tr>
              </React.Fragment>
            ))}
          </MDBTableBody>
        </MDBTable>
      </Container>

      <MDBRow>
        <MDBCol md="6">
          <SubmitButton onClick={handleSelectAll}>
            Selecionar todos
          </SubmitButton>
        </MDBCol>
        <MDBCol md="6">
          <SubmitButton onClick={handleDeselectAll}>
            Limpar seleção
          </SubmitButton>
        </MDBCol>
      </MDBRow>

      <SubmitButton onClick={handleGeneratePDF}>
        Gerar e Baixar PDF
      </SubmitButton>
    </>
  );
};

export default PrintQRCode;

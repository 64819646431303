import React from "react";
import { Container } from "./styles";

function ButtonGreen(props) {
  const { label, onClick, className, style } = props;
  return (
    <div>
      <Container type={props.type} onClick={onClick} className={className} style={style}>
        {label}
      </Container>
    </div>
  );
}

export default ButtonGreen;

// Commissions.js
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "./styles";
import {
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import api from "../../services/api";
import moment from "moment";

function Commissions() {
  const client_id = new URLSearchParams(useLocation().search).get("client_id");
  const store_code = new URLSearchParams(useLocation().search).get(
    "store_code"
  );

  const navigate = useNavigate();

  const [ESLs, setESLs] = useState();

  useEffect(() => {
    api.get(`/logs/commission`).then((response) => {
      setESLs(response.data);
    });
  }, []);

  return (
    <Container>
      <h3>Comissionamentos e Descomissionamentos</h3>

      <MDBTable align="start" hover>
        <MDBTableHead>
          <tr>
            <th>Mac</th>
            <th>Ação</th>
            <th>GTIN</th>
            <th>Data</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {ESLs &&
            ESLs.map((esl) =>
              esl.actions.map((action) => (
                <tr
                  className="table-light"
                  onClick={() => navigate(`/details/${esl.mac}`)}
                >
                  <td>{action.mac}</td>

                  {action.action === "commission" && <td>Comissionada</td>}
                  {action.action === "uncommission" && <td>Descomissionada</td>}
                  <td>{action.productGtin}</td>
                  <td>
                    {moment
                      .utc(action.timestamp)
                      .format("HH:mm:ss - DD/MM/YYYY")}
                  </td>
                </tr>
              ))
            )}
        </MDBTableBody>
      </MDBTable>
    </Container>
  );
}

export default Commissions;

import styled from "styled-components";

export const Container = styled.button`
  background-color: #41A017;
  border-radius: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: solid;
  border-color: #41A017;
  cursor: pointer;
  border-width: 1px;
  :hover {
    background-color: #158110;
    border-color: #158110;
    border-width: 1px;
  };
  :focus {
    background-color: #158110;
    border-color: #41A017;
    border-width: 1px;
  }
`;

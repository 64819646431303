// Register.js

import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Container, Square } from "./styles";
import ButtonGreen from "../../components/ButtonGreen/ButtonGreen";
import Input from "../../components/Input/Input";
import Select from "../../components/Select/Select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import api from "../../services/api";
import InputMak from "../../components/Input/Input-Mask";

const Register = () => {
  const [stores, setStores] = useState([]);
  const [clients, setClients] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [code, setcode] = useState("");
  const [clientId, setClientId] = useState("");
  const [storeCode, setStoreCode] = useState("");
  const [idProfile, setIdProfile] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const [selectionClient, setSelectionClient] = useState("");

  const handleSelectClientChange = (value) => {
    setSelectionClient(value);
    setClientId(value);
  };

  const [selectionStore, setSelectionStore] = useState("");

  const handleSelectStoreChange = (value) => {
    setSelectionStore(value);
    setStoreCode(value);
  };

  const [selectionProfile, setSelectionProfile] = useState("");

  const handleSelectProfileChange = (value) => {
    setSelectionProfile(value);
    setIdProfile(value);
  };

  const formatPhoneNumber = (value) => {
    // Remove todos os caracteres não numéricos
    const numericValue = value.replace(/[^0-9]/g, "");

    // Verifique o comprimento do número de celular
    if (numericValue.length <= 2) {
      // Formato: (XX
      return `(${numericValue}`;
    } else if (numericValue.length <= 6) {
      // Formato: (XX) XXXX
      const firstPart = numericValue.slice(0, 2);
      const secondPart = numericValue.slice(2);
      return `(${firstPart}) ${secondPart}`;
    } else {
      // Formato: (XX) XXXX-XXXX
      const firstPart = numericValue.slice(0, 2);
      const secondPart = numericValue.slice(2, 6);
      const thirdPart = numericValue.slice(6);
      return `(${firstPart}) ${secondPart}-${thirdPart}`;
    } 
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        client_id: clientId,
        store_code: storeCode,
        code: code,
        name: name,
        phone_number: phoneNumber.replace(/\s|\(|\)|-/g, ""),
        email: email,
        password: password,
        profile: parseInt(idProfile),
      };

      if (
        !clientId ||
        !storeCode ||
        !code ||
        !name ||
        !phoneNumber ||
        !email ||
        !password ||
        !idProfile
      ) {
        toast.error("Por favor preencha todos os campos");
        return;
      }

      api
        .post("/employees", data)
        .then((response) => {
          toast.success("Funcionário cadastrado com sucesso!");
          navigate("/login");
        })
        .catch((error) => {
          toast.error(`${JSON.parse(error.request.response).message}`);
          console.log(
            "err.message",
            JSON.parse(error.request.response).message
          );
        });
    } catch (err) {
      if (err instanceof Error) {
        toast.error(`${JSON.parse(err.request.response).message}`);
        console.log("err.message", JSON.parse(err.request.response).message);
      }
    }
  };

  useState(() => {
    api.get("/stores").then((response) => {
      setStores(response.data);
    });
    api.get("/clients").then((response) => {
      setClients(response.data);
    });
    api.get("/profiles").then((response) => {
      setProfiles(response.data);
    });
  }, []);

  return (
    <>
      <ToastContainer />
      <Container>
        <MDBRow style={{ width: "50%" }}>
          <MDBCol
            lg="12"
            className="text-start"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Square>
              {/* Forms de email e senha*/}
              <form onSubmit={handleSubmit}>
                <MDBRow>
                  <MDBCol size={6}>
                    <h6>LDAP</h6>
                    <Input
                      type="text"
                      autoComplete="code"
                      name="code"
                      value={code || ""}
                      onChange={(event) => setcode(event.target.value)}
                    />
                    <h6>Loja</h6>
                    <Select
                      label="Selecione uma loja"
                      options={clients.map((option) => ({
                        value: option.id,
                        label: option.name,
                      }))}
                      selection={selectionClient}
                      onChange={handleSelectClientChange}
                    />
                    <h6>Unidade</h6>
                    <Select
                      label="Selecione uma unidade"
                      options={stores.map((option) => ({
                        value: option.code,
                        label: option.code + " - " + option.location,
                      }))}
                      selection={selectionStore}
                      onChange={handleSelectStoreChange}
                    />
                    <h6>Nome</h6>
                    <Input
                      type="text"
                      autoComplete="name"
                      name="name"
                      value={name || ""}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </MDBCol>
                  <MDBCol size={6}>
                    <h6>Celular</h6>
                    <InputMak
                      mask="(99) 9 9999-9999"
                      type="text"
                      autoComplete="phone_number"
                      name="phone_number"
                      placeholder="(XX) XXXX-XXXX"
                      value={phoneNumber || ""}
                      onChange={(event) =>
                        {
                          setPhoneNumber(formatPhoneNumber(event.target.value))
                        }
                      }
                    />
                    <h6>Cargo</h6>
                    <Select
                      label="Selecione um cargo"
                      options={profiles.map((option) => ({
                        value: option.id,
                        label: option.name,
                      }))}
                      selection={selectionProfile}
                      onChange={handleSelectProfileChange}
                    />
                    <h6>E-mail</h6>
                    <Input
                      type="text"
                      autoComplete="email"
                      name="email"
                      value={email || ""}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    <h6>Senha</h6>
                    <Input
                      type="password"
                      autoComplete="current-password"
                      name="senha"
                      value={password || ""}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <ButtonGreen type="submit" label="Registrar" />
                  </MDBCol>
                </MDBRow>
              </form>
            </Square>
          </MDBCol>
        </MDBRow>
      </Container>
    </>
  );
};

export default Register;

/* eslint-disable react-hooks/exhaustive-deps */
// ESLs.js
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, ESLsSquare, Infos } from "./styles";
import {
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
} from "mdb-react-ui-kit";
import "material-symbols";
import api from "../../services/api";

import ButtonSequence from "../../components/ButtonSequence/ButtonSequence ";
import Dropdown from "../../components/Dropdown/Dropdown";
import ButtonGrey from "../../components/ButtonGrey/ButtonGrey";
import Input from "../../components/Input/Input";
import BlockedInput from "../../components/BlockedInput/BlockedInput";

function ESLs() {
  const navigate = useNavigate();
  const client_id = new URLSearchParams(useLocation().search).get("client_id");
  const store_code = new URLSearchParams(useLocation().search).get(
    "store_code"
  );
  const profundidadeUmURL = new URLSearchParams(useLocation().search)
    .get("profundidadeUm")
    .slice(0, 2);
  const profundidadeDoisURL = new URLSearchParams(useLocation().search)
    .get("profundidadeDois")
    .slice(0, 2);
  const profundidadeTresURL = new URLSearchParams(useLocation().search)
    .get("profundidadeTres")
    .slice(0, 2);

  const location = new URLSearchParams(useLocation().search).get("location");

  const [eslsByShelf, setEslsByShelf] = useState([]);
  const [esls, setEsls] = useState([]);
  const [shelves, setShelves] = useState([]);
  const [status, setStatus] = useState("Carregando");
  const [filterVisible, setFilterVisible] = useState("none");
  const [stores, setStores] = useState([]);
  const [sections, setSections] = useState([]);
  const [aisles, setSisles] = useState([]);
  const [modules, setModules] = useState([]);

  const [gtin, setGtin] = useState("");
  const [mac, setMac] = useState("");

  const [storeSelected, setStoreSelected] = useState({
    store_code: store_code,
    location: location,
  });

  const [sectionSelected, setSectionSelected] = useState(profundidadeUmURL);
  const [aisleSelected, setAisleSelected] = useState("Todos");
  const [moduleSelected, setModuleSelected] = useState("Todos");

  const handleStoreSelect = (selectedOption) => {
    setSectionSelected("Todas");
    setAisleSelected("Todos");
    setModuleSelected("Todos");

    api
      .get(
        `/esls/details?client_id=${client_id}&store_code=${store_code}&profundidadeUm=${profundidadeUmURL}&profundidadeDois=${profundidadeDoisURL}&profundidadeTres=${profundidadeTresURL}`
      )
      .then((response) => {
        if (response.data.length === 0) {
          setEsls([]);
        } else {
          setEsls(response.data);
        }
      })
      .catch((error) => {
        setEsls([]);
      });
  };

  useEffect(() => {
    if (gtin.length === 13) {
      handleSearchGtin();
    }
  }, [gtin]);

  useEffect(() => {
    if (mac.length === 12) {
      handleSearchMac();
    }
  }, [mac]);

  function handleSearchGtin() {
    api
      .get(
        `/esls/details/gtin/${client_id}/${store_code}/${profundidadeUmURL}/${gtin}`
      )
      .then((response) => {
        setEslsByShelf(
          response.data.reduce((acc, cur) => {
            const level = cur.level.trim();
            const slot = cur.slot.trim();
            if (!acc[level]) {
              acc[level] = {};
            }
            if (!acc[level][slot]) {
              acc[level][slot] = [];
            }
            acc[level][slot].push(cur);
            return acc;
          }, {})
        );
      })
      .catch((error) => {
        setEslsByShelf([]);
        setStatus("Erro");
      });
  }

  const handleClickNotCommissioned = () => {
    api
      .get(
        `/esls/details/notcommissioned/section/${client_id}/${store_code}/${profundidadeUmURL}`
      )
      .then((response) => {
        setEslsByShelf(
          response.data.reduce((acc, cur) => {
            const level = cur.level.trim();
            const slot = cur.slot.trim();
            if (!acc[level]) {
              acc[level] = {};
            }
            if (!acc[level][slot]) {
              acc[level][slot] = [];
            }
            acc[level][slot].push(cur);
            return acc;
          }, {})
        );
      })
      .catch((error) => {
        setEslsByShelf([]);
        setStatus("Erro");
      });
  };

  const handleClickCommissioned = () => {
    api
      .get(
        `/esls/details/commissioned/section/${client_id}/${store_code}/${profundidadeUmURL}`
      )
      .then((response) => {
        setEslsByShelf(
          response.data.reduce((acc, cur) => {
            const level = cur.level.trim();
            const slot = cur.slot.trim();
            if (!acc[level]) {
              acc[level] = {};
            }
            if (!acc[level][slot]) {
              acc[level][slot] = [];
            }
            acc[level][slot].push(cur);
            return acc;
          }, {})
        );
      })
      .catch((error) => {
        setEslsByShelf([]);
        setStatus("Erro");
      });
  };

  function handleSearchMac() {
    api.get(`/esls/details/mac/${mac}`).then((response) => {
      setEslsByShelf(
        response.data.reduce((acc, cur) => {
          const level = cur.level.trim();
          const slot = cur.slot.trim();
          if (!acc[level]) {
            acc[level] = {};
          }
          if (!acc[level][slot]) {
            acc[level][slot] = [];
          }
          acc[level][slot].push(cur);
          return acc;
        }, {})
      );
    });
  }

  function init() {
    api.get(`/stores`).then((response) => setStores(response.data));

    api
      .get(
        `/esls/details?client_id=${client_id}&store_code=${store_code}&profundidadeUm=${profundidadeUmURL}&profundidadeDois=${profundidadeDoisURL}&profundidadeTres=${profundidadeTresURL}`
      )
      .then((response) => {
        const aux = []

        response.data.map(item => aux.push({
          mac: item.mac,
          product_gtin: item.product_gtin ?? null,
          depth_0: item.depth_0,
          depth_1: item.depth_1,
          depth_2: item.depth_2
        }))
        setEsls(aux);
      });
  }

  useEffect(() => {
    init();
  }, [
    client_id,
    store_code,
    profundidadeUmURL,
    profundidadeDoisURL,
    profundidadeTresURL,
  ]);

  function handleSquareClick(mac) {
    navigate(`/details/${mac}`);
  }

  return (
    <Container>
      <MDBRow>
        <MDBCol size="10">
          <h3 className="text-start">Etiquetas</h3>
        </MDBCol>
        <MDBCol
          size="2"
          onClick={() => {
            if (filterVisible === "none") {
              setFilterVisible("flex");
            } else {
              init();
              setFilterVisible("none");
            }
          }}
        >
          <h3 className="text-end">
            {filterVisible === "none" ? (
              <button
                className="material-symbols-outlined"
                style={{ borderStyle: "none", backgroundColor: "transparent" }}
              >
                filter_alt
              </button>
            ) : (
              <button
                className="material-symbols-outlined"
                style={{ borderStyle: "none", backgroundColor: "transparent" }}
              >
                close
              </button>
            )}
          </h3>
        </MDBCol>
      </MDBRow>
      <Infos>
        <MDBRow className="text-start">
          <MDBCol lg="12">
            {/*
            <MDBRow>
              <MDBCol md="6" className="text-start">
                <MDBRow>
                  <MDBCol md="6" className="d-flex align-items-center">
                    <h5>Total de etiquetas instaladas:</h5>
                  </MDBCol>
                  <MDBCol md="6">
                    <BlockedInput placeholder="79" name="email" disabled />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol md="6" className="text-start">
                <MDBRow>
                  <MDBCol md="7" className="d-flex align-items-center">
                    <h5>Etiquetas que necessitam de manutenção:</h5>
                  </MDBCol>
                  <MDBCol md="5">
                    <BlockedInput placeholder="0" name="email" disabled />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            */}

            <MDBRow
              className="text-start"
              style={{ display: `${filterVisible}` }}
            >
              <MDBCol md="4">
                <h4>Mac:</h4>
                <Input
                  type="text"
                  autoComplete="mac"
                  name="mac"
                  placeholder="Insira o MAC de 12 dígitos"
                  value={mac}
                  onChange={(e) => setMac(e.target.value)}
                />
              </MDBCol>
              <MDBCol md="4">
                <h4>GTIN:</h4>
                <Input
                  type="text"
                  autoComplete="gtin"
                  name="gtin"
                  placeholder="Insira o GTIN de 13 dígitos"
                  value={gtin}
                  onChange={(e) => setGtin(e.target.value)}
                />
              </MDBCol>
              <MDBCol md="4">
                <h4>Situação:</h4>
                <MDBRow>
                  <MDBCol md="6">
                    <ButtonGrey
                      onClick={handleClickCommissioned}
                      label="Comissionadas"
                    />
                  </MDBCol>
                  <MDBCol md="6">
                    <ButtonGrey
                      onClick={handleClickNotCommissioned}
                      label="Não comissionadas"
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            {/*filterVisible === "none" && (
              <MDBRow className="text-start">
                <MDBCol lg="4" style={{ display: "flex" }}>
                  <h4>Prateleira:</h4>
                  <ButtonSequence
                    numbers={shelves}
                    onButtonClick={handleButtonClickShelf}
                  />
                </MDBCol>
              </MDBRow>
            )*/}

            <MDBTable align="start" hover responsive>
              <MDBTableHead>
                <tr className="text-center">
                  <th scope="col">MAC</th>
                  <th scope="col">GTIN</th>
                  <th scope="col">Seção/Corredor/Módulo</th>
                  <th scope="col">Detalhes</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {esls.length === 0 ? (
                  <tr>
                    <td colSpan="5">
                      <p className="text-center" style={{ marginTop: "1%" }}>
                        Nenhuma ESL encontrada.
                      </p>
                    </td>
                  </tr>
                ) : (
                  esls.map((esl) => (
                    <tr
                      key={esl.mac}
                      className="table-light text-center"
                      onClick={() => handleSquareClick(esl.mac)}
                    >
                      <td>{esl.mac}</td>
                      <td>{esl.product_gtin}</td>
                      <td>
                        {esl.depth_0}/{esl.depth_1}/{esl.depth_2}
                      </td>
                      <td>
                        <MDBIcon
                          icon="eye"
                          fas
                          onClick={() => {
                            if(esl.product_gtin)
                              handleSquareClick(esl.mac)
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </MDBTableBody>
            </MDBTable>
          </MDBCol>
        </MDBRow>
      </Infos>
    </Container>
  );
}

export default ESLs;

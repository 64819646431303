import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import { useLocation, Link } from "react-router-dom";
import logoLeroy from "../../img/logo-leroy.svg";

import api from "../../services/api";
import useAuth from "../../hooks/auth";

const Navbar = () => {
  const [showBasic, setShowBasic] = useState(false);

  const { logout } = useAuth();

  const SAP = localStorage.getItem("code");
  var isSAP = false;

  if (SAP === "012345678910") {
    isSAP = true;
  }

  return (
    <MDBNavbar expand="lg" dark style={{ backgroundColor: "#78BE20" }}>
      <MDBContainer fluid>
        <MDBNavbarBrand href="/home" style={{ color: "white", width: "50px" }}>
          <img src={logoLeroy} alt="Torneira Leroy Merlin" width="100%" />
        </MDBNavbarBrand>
        <MDBNavbarToggler
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowBasic(!showBasic)}
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>
        {!isSAP && (
          <MDBCollapse navbar show={showBasic}>
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
              <MDBNavbarItem>
                <MDBNavbarLink href="/home" style={{ color: "white" }}>
                  Página Inicial
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href="/stores" style={{ color: "white" }}>
                  Visão Geral
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle
                    tag="a"
                    className="nav-link"
                    role="button"
                    style={{
                      color: "white",
                    }}
                  >
                    Etiquetas e Produtos
                  </MDBDropdownToggle>
                  <MDBDropdownMenu
                    style={{
                      width: "100%",
                      borderRadius: "0px",
                      backgroundColor: "#78BE20",
                    }}
                  >
                    <Link to="/searchlocalization">
                      <MDBDropdownItem
                        style={{
                          color: "white",
                          padding: "10px",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.backgroundColor = "#ADD585";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.backgroundColor = "#78BE20";
                        }}
                      >
                        Buscar por localização
                      </MDBDropdownItem>
                    </Link>
                    <Link to="/searchproduct">
                      <MDBDropdownItem
                        style={{
                          color: "white",
                          padding: "10px",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.backgroundColor = "#ADD585";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.backgroundColor = "#78BE20";
                        }}
                      >
                        Buscar por produto
                      </MDBDropdownItem>
                    </Link>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href="/productUpdate" style={{ color: "white" }}>
                  Atualizações de Preços
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle
                    tag="a"
                    className="nav-link"
                    role="button"
                    style={{
                      color: "white",
                      padding: "10px",
                    }}
                  >
                    Visualizar Instalações e Comissionamentos
                  </MDBDropdownToggle>
                  <MDBDropdownMenu
                    style={{
                      width: "100%",
                      borderRadius: "0px",
                      backgroundColor: "#78BE20",
                    }}
                  >
                    <Link to="/installs">
                      <MDBDropdownItem
                        style={{
                          color: "white",
                          padding: "10px",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.backgroundColor = "#ADD585";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.backgroundColor = "#78BE20";
                        }}
                      >
                        Visualizar Instalações/Desinstalações
                      </MDBDropdownItem>
                    </Link>
                    <Link to="/commissions">
                      <MDBDropdownItem
                        style={{
                          color: "white",
                          padding: "10px",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.backgroundColor = "#ADD585";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.backgroundColor = "#78BE20";
                        }}
                      >
                        Visualizar Comissionamentos/Descomissionamentos
                      </MDBDropdownItem>
                    </Link>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle
                    tag="a"
                    className="nav-link"
                    role="button"
                    style={{
                      color: "white",
                    }}
                  >
                    Carregamentos Iniciais
                  </MDBDropdownToggle>
                  <MDBDropdownMenu
                    style={{
                      width: "100%",
                      borderRadius: "0px",
                      backgroundColor: "#78BE20",
                    }}
                  >
                    <Link to="/insertESL">
                      <MDBDropdownItem
                        style={{
                          color: "white",
                          padding: "10px",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.backgroundColor = "#ADD585";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.backgroundColor = "#78BE20";
                        }}
                      >
                        Inserir novas ESLs
                      </MDBDropdownItem>
                    </Link>
                    <Link to="/insertSGLN">
                      <MDBDropdownItem
                        style={{
                          color: "white",
                          padding: "10px",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.backgroundColor = "#ADD585";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.backgroundColor = "#78BE20";
                        }}
                      >
                        Inserir novos GLNs
                      </MDBDropdownItem>
                    </Link>
                    <Link to="/insertGateway">
                      <MDBDropdownItem
                        style={{
                          color: "white",
                          padding: "10px",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.backgroundColor = "#ADD585";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.backgroundColor = "#78BE20";
                        }}
                      >
                        Inserir novos Gateways
                      </MDBDropdownItem>
                    </Link>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
              <div className="ms-auto">
                <MDBNavbarNav>
                  <MDBNavbarItem>
                    <MDBNavbarLink onClick={logout} style={{ color: "white" }}>
                      <span
                        className="material-symbols-outlined"
                        style={{ marginRight: "5px", cursor: "pointer" }}
                      >
                        logout
                      </span>
                    </MDBNavbarLink>
                  </MDBNavbarItem>
                </MDBNavbarNav>
              </div>
            </MDBNavbarNav>
          </MDBCollapse>
        )}
        {isSAP && (
          <MDBCollapse navbar show={showBasic}>
            <MDBNavbarNav>
              <MDBNavbarItem>
                <MDBNavbarLink href="/inserirAVRO" style={{ color: "white" }}>
                  Inserir AVRO
                </MDBNavbarLink>
              </MDBNavbarItem>
              <div className="ms-auto">
                <MDBNavbarNav>
                  <MDBNavbarItem>
                    <MDBNavbarLink onClick={logout} style={{ color: "white" }}>
                      <span
                        className="material-symbols-outlined"
                        style={{ marginRight: "5px", cursor: "pointer" }}
                      >
                        logout
                      </span>
                    </MDBNavbarLink>
                  </MDBNavbarItem>
                </MDBNavbarNav>
              </div>
            </MDBNavbarNav>
          </MDBCollapse>
        )}
      </MDBContainer>
    </MDBNavbar>
  );
};
export default Navbar;

import React from "react";
import { Container } from "./styles";

function ButtonGrey(props) {
  const { label, onClick, className } = props;
  return (
    <div className="button">
      <Container type={props.type} onClick={onClick} className={className}>
        {label}
      </Container>
    </div>
  );
}

export default ButtonGrey;

// ESLDetails.js
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Container, Square } from "./styles";
import {
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import "material-symbols";
import api from "../../services/api";
import BlockedInput from "../../components/BlockedInput/BlockedInput";

const ESLDetails = () => {
  const [esl, setESL] = useState([]);
  const [actions, setActions] = useState();
  const mac = useLocation().pathname.split("/")[2];

  useEffect(() => {
    api.get(`/esls/details/mac/${mac}`).then((response) => {
      setESL(response.data[0]);
    });
    api.get(`/logs/mac/${mac}`).then((response) => {
      console.log(response.data[0].actions);
      setActions(response.data[0].actions);
    });
  }, [mac]);

  return (
    <Container>
      <MDBRow className="text-start">
        <h3>Etiquetas</h3>

        <MDBRow>
          <MDBCol md="7" className="text-start">
            <MDBRow>
              <MDBCol md="6" className="d-flex align-items-center">
                <h5>Nome do produto</h5>
              </MDBCol>
              <MDBCol md="6">
                <BlockedInput
                  placeholder={esl.product_title}
                  name="product_title"
                  disabled
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="6" className="d-flex align-items-center">
                <h5>Código LM</h5>
              </MDBCol>
              <MDBCol md="6">
                <BlockedInput
                  placeholder={esl.product_gtin}
                  name="product_gtin"
                  disabled
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="6" className="d-flex align-items-center">
                <h5>Código de barras</h5>
              </MDBCol>
              <MDBCol md="6">
                <BlockedInput
                  placeholder={esl.product_internal_client_code}
                  name="product_internal_client_code"
                  disabled
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="6" className="d-flex align-items-center">
                <h5>Preço atual</h5>
              </MDBCol>
              <MDBCol md="6">
                {esl.msg_timestamp ? (
                  <BlockedInput
                    placeholder={`R$ ${esl.product_price}`}
                    name="product_price"
                    disabled
                  />
                ) : (
                  <BlockedInput placeholder="" name="msg_timestamp" disabled />
                )}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="6" className="d-flex align-items-center">
                <h5>Última atualização de preço</h5>
              </MDBCol>
              <MDBCol md="6">
                {esl.msg_timestamp ? (
                  <BlockedInput
                    placeholder={moment
                      .utc(esl.msg_timestamp)
                      .format("HH:mm:ss - DD/MM/YYYY")}
                    name="msg_timestamp"
                    disabled
                  />
                ) : (
                  <BlockedInput placeholder="" name="msg_timestamp" disabled />
                )}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="6" className="d-flex align-items-center">
                <h5>Avaliação</h5>
              </MDBCol>
              <MDBCol md="6">
                <BlockedInput
                  placeholder={esl.product_rating}
                  name="product_rating"
                  disabled
                />
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol md="5" className="text-start">
            <MDBRow>
              <MDBCol
                md="12"
                className="text-center"
                style={{ marginBottom: "2%" }}
              >
                {esl.render ? (
                  <img
                    src={`data:image/jpeg;base64,${esl.render.img}`}
                    alt={esl.product_title}
                    width="40%"
                  />
                ) : (
                  <img
                    src="https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found.jpg"
                    alt={esl.product_title}
                    width="40%"
                  />
                )}
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ marginTop: "5%" }}>
              <MDBCol md="12" className="text-start">
                <h5>Local da etiqueta</h5>
                <MDBRow>
                  <MDBCol md="4" className="d-flex align-items-center">
                    <h5>Seção</h5>
                  </MDBCol>
                  <MDBCol md="8">
                    <BlockedInput
                      placeholder={esl.depth_0}
                      name="depth_0"
                      disabled
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="4" className="d-flex align-items-center">
                    <h5>Corredor</h5>
                  </MDBCol>
                  <MDBCol md="8">
                    <BlockedInput
                      placeholder={esl.depth_1}
                      name="depth_1"
                      disabled
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="4" className="d-flex align-items-center">
                    <h5>Módulo</h5>
                  </MDBCol>
                  <MDBCol md="8">
                    <BlockedInput
                      placeholder={esl.depth_2}
                      name="depth_2"
                      disabled
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBRow>

      {
        <MDBRow className="text-start" style={{ marginTop: "10px" }}>
          <MDBTable align="center" className="text-center">
            <MDBTableHead>
              <tr>
                <th scope="col">Data e hora</th>
                <th scope="col">Ação</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {actions?.map((action) => (
                <tr className="table-light">
                  <td>
                    {moment
                      .utc(action.timestamp)
                      .format("HH:mm:ss - DD/MM/YYYY")}
                  </td>
                  {action.action === "commission" && <td>Comissionada</td>}
                  {action.action === "uncommission" && <td>Descomissionada</td>}
                  {action.action === "install" && <td>Instalada</td>}
                  {action.action === "uninstall" && <td>Desinstalada</td>}
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        </MDBRow>
      }
    </Container>
  );
};

export default ESLDetails;

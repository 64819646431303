// DropdownStyles.js
import styled from "styled-components";

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 1rem;
`;

export const DropdownLabel = styled.label`
  cursor: pointer;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    margin-left: 4px;
    font-size: 10px;
    vertical-align: middle;
    line-height: 1;
  }
`;

export const DropdownOptions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1; /* Adiciona um z-index para definir a ordem de empilhamento */
`;

export const DropdownOption = styled.div`
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #eaf3e2;
  }

  &.selected {
    background-color: #cbe3b5;
  }
`;

import styled from "styled-components";

export const Container = styled.div`
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 2%;

  h3 {
    font-weight: bold;
    margin-top: 2%;
    margin-bottom: 1%;
  }
`;

export const Square = styled.div`
  background-color: white;
  width: 100%;
  justify-content: center;
  border-radius: 5px;
  text-align: center;
  height: 100%;
  padding: 3% 5% 3% 5%;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 3px 1px;

  h5,
  span {
    font-weight: bold;
  }
`;

import React, { useState, useEffect } from "react";
import { Container, SubmitButton, Title } from "./styles";
import Uploader from "../../components/Uploader/Uploader";
import api from "../../services/api";
import * as XLSX from "xlsx";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MDBRow, MDBProgress, MDBProgressBar, MDBCol } from "mdb-react-ui-kit";
import Input from "../../components/Input/Input";
import ButtonGreen from "../../components/ButtonGreen/ButtonGreen";

const InsertSGLN = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const leroyCode = localStorage.getItem("code");
  const [successCount, setSuccessCount] = useState(0);
  const [errors, setErrors] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const [storeCode, setStoreCode] = useState("");
  const [depth0, setDepht0] = useState("");
  const [depth1, setDepht1] = useState("");
  const [depth2, setDepht2] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        sgln: `storeCode${storeCode}clientId1sec${depth0}cor${depth1}mod${depth2}`,
        client_id: 1,
        store_code: storeCode,
        depth_0: depth0.toLocaleUpperCase(),
        depth_1: depth1.toString(),
        depth_2: depth2.toString(),
        depth_3: null,
        depth_4: null,
        depth_5: null,
      };

      api
        .post(`/insert/sgln`, data)
        .then((response) => {
          toast.success(`SGLN ${response.data.sgln} inserido com sucesso!`);
          setStoreCode("");
          setDepht0("");
          setDepht1("");
          setDepht2("");
        })
        .catch((error) => {
          toast.error("Erro ao inserir SGLN.");
        });
    } catch (err) {
      if (err instanceof Error) {
        console.log("err.message", err.message);
      }
    }
  };

  useEffect(() => {
    if (progress === 100) {
      setProgress(100);
    }

    if (isProcessing && progress === 100) {
      setIsProcessing(false);

      toast.success(`SGLNs inseridos com sucesso!`);
      if (errors.length > 0) {
        errors.forEach((error) => {
          toast.error(`SGLN ${error.sgln}  duplicado!`);
        });
      }
    }
  }, [isProcessing, progress, successCount, errors]);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  const handleFileLoad = (event) => {
    const fileData = event.target.result;
    const workbook = XLSX.read(fileData, { type: "array" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    const promises = [];
    const newErrors = [];

    setProgress(0);
    setSuccessCount(0);
    setErrors([]);
    setIsProcessing(true);

    console.log(jsonData);

    jsonData.forEach((row, index) => {
      const values = Object.values(row);

      const data = {
        sgln: `storeCode${values[0]}clientId1sec${values[1]}cor${values[2]}mod${values[3]}`,
        client_id: 1,
        store_code: values[0],
        depth_0: values[1],
        depth_1: values[2],
        depth_2: values[3],
        depth_3: null,
        depth_4: null,
        depth_5: null,
      };

      console.log(data);

      const promise = new Promise((resolve) => {
        api
          .post(`/insert/sgln`, data)
          .then(() => {
            resolve({ success: true, sgln: data.sgln });
          })
          .catch((error) => {
            let errorMessage = "Erro ao inserir SGLN";
            if (
              error.response &&
              error.response.data &&
              error.response.headers["content-type"].includes(
                "application/json"
              )
            ) {
              try {
                errorMessage = JSON.parse(error.response.data).message;
              } catch (parseError) {
                console.error(
                  "Erro ao fazer o parse da resposta JSON",
                  parseError
                );
              }
            }
            newErrors.push({ sgln: data.sgln, error: errorMessage });
            resolve({ success: false, sgln: data.sgln, error: errorMessage });
          })
          .finally(() => {
            const newProgress = Math.floor(
              ((index + 1) / jsonData.length) * 100
            );
            setProgress(newProgress);
          });
      });

      promises.push(promise);
    });

    Promise.all(promises)
      .then((results) => {
        const count = results.filter((result) => result.success).length;
        setSuccessCount(count);
        setErrors(newErrors);
        setProgress(100);
      })
      .catch((error) => {
        toast.error("Erro ao inserir SGLN.");
      });
  };

  const handleSendButtonClick = () => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = handleFileLoad;
      reader.readAsArrayBuffer(selectedFile);
    }
  };

  return (
    <>
      <ToastContainer />
      <Title className="text-start">
        <h3>Inserir novos SGLNs</h3>
        {/*<MDBRow>
          <MDBProgress height="20">
            <MDBProgressBar width={progress} striped animated>
              {parseInt(progress)}%
            </MDBProgressBar>
          </MDBProgress>
  </MDBRow>*/}
      </Title>
      <Container>
        <form onSubmit={handleSubmit}>
          <Input
            type="text"
            autoComplete="storeCode"
            name="Códido da loja"
            value={storeCode || ""}
            placeholder="Código da loja"
            onChange={(event) => setStoreCode(event.target.value)}
          />
          <br />
          <Input
            type="text"
            autoComplete="secao"
            name="secao"
            value={depth0 || ""}
            placeholder="Seção"
            onChange={(event) => setDepht0(event.target.value)}
          />
          <br />
          <Input
            type="text"
            autoComplete="corredor"
            name="corredor"
            value={depth1 || ""}
            placeholder="Corredor"
            onChange={(event) => setDepht1(event.target.value)}
          />
          <br />
          <Input
            type="text"
            autoComplete="modulo"
            name="modulo"
            value={depth2 || ""}
            placeholder="Módulo"
            onChange={(event) => setDepht2(event.target.value)}
          />
          <br />
          <MDBRow>
            <MDBCol>
              <ButtonGreen type="submit" label="Inserir" />
            </MDBCol>
          </MDBRow>
        </form>
        <MDBRow>
          <Uploader onFileSelect={handleFileSelect} />
        </MDBRow>

        <MDBRow>
          <SubmitButton
            onClick={handleSendButtonClick}
            disabled={!selectedFile || isProcessing}
          >
            Enviar
          </SubmitButton>
        </MDBRow>
      </Container>
    </>
  );
};

export default InsertSGLN;

// StoreMapCorredores.js
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, SectionSquare } from "./styles";
import {
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
} from "mdb-react-ui-kit";
import "material-symbols";
import api from "../../services/api";
import { ButtonEye } from "../../components/ButtonEye/ButtonEye";

function StoreMapCorredores() {
  const client_id = new URLSearchParams(useLocation().search).get("client_id");
  const store_code = new URLSearchParams(useLocation().search).get(
    "store_code"
  );
  const section = new URLSearchParams(useLocation().search)
    .get("profundidadeUm")
    .slice(0, 2);
  const location = new URLSearchParams(useLocation().search).get("location");

  const navigate = useNavigate();

  function handleRowClick(client_id, store_code, section, aisle) {
    navigate(
      `/storemapmodulos?client_id=${client_id}&store_code=${store_code}&location=${location}&profundidadeUm=${section}&profundidadeDois=${aisle}`
    );
  }

  const [infosSections, setInfosSections] = useState([]);

  useEffect(() => {
    api
      .get(
        `/physicalOrganization/profundidadesDois/${client_id}/${store_code}/${section}`
      )
      .then((response) => {
        Promise.all(
          response.data.map(async (corredor) => {
            const response = await api.get(
              `/infos/dataProfundidadeDois/${client_id}/${store_code}/${section}/${corredor.depth_1}`
            );
            return {
              ...corredor,
              ...response.data,
            };
          })
        ).then((responses) => {
          const uniqueResponses = responses.filter(
            (value, index, self) =>
              self.findIndex(
                (v) =>
                  v.client_id === value.client_id &&
                  v.store_code === value.store_code &&
                  v.depth_1 === value.depth_1
              ) === index
          );
          setInfosSections(uniqueResponses);
        });
      });
  }, [client_id, section, store_code]);

  return (
    <Container>
      <h3 className="d-flex align-items-left">
        Visão geral - Loja {location} - Seção {section}
      </h3>

      <MDBTable align="start" hover responsive>
        <MDBTableHead>
          <tr className="text-center">
            <th scope="col">Corredor</th>
            <th scope="col">Etiquetas instaladas</th>
            <th scope="col">Etiquetas comissionadas</th>
            <th scope="col">Etiquetas não comissionadas</th>
            <th scope="col">Etiquetas que necessitam de manutenção</th>
            <th scope="col">Ver detalhes</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {infosSections.map((info) => (
            <tr
              key={info.depth_1}
              className="table-light text-center"
              onClick={() =>
                handleRowClick(
                  info[0].client_id,
                  info[0].store_code,
                  info.depth_0,
                  info.depth_1
                )
              }
            >
              <td>{info.depth_1}</td>
              <td>{info[0].totalESLs}</td>
              <td>{info[0].totalESLs - info[0].notCommissionedESLs}</td>
              <td>{info[0].notCommissionedESLs}</td>
              <td>{info[0].isntValidESLs}</td>
              <td>
                <ButtonEye />
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>

      <MDBRow>
        {/*infosSections?.map((info) => (
          <MDBCol md="3">
            <SectionSquare
              onClick={() =>
                handleRowClick(
                  info[0].client_id,
                  info[0].store_code,
                  info.depth_0,
                  info.depth_1
                )
              }
            >
              <MDBRow>
                <h5
                  className="d-flex align-items-center"
                  style={{ display: "flex" }}
                >
                  <strong>Corredor: {info.depth_2}</strong>
                </h5>
              </MDBRow>
              <MDBRow>
                <p
                  className="d-flex align-items-center"
                  style={{ display: "flex" }}
                >
                  <span className="material-symbols-outlined">123</span>{" "}
                  <strong style={{ marginRight: "5px" }}>Instaladas:</strong>
                  {info[0].totalESLs} ESLs
                </p>
              </MDBRow>
              <MDBRow>
                <p
                  className="d-flex align-items-center"
                  style={{ display: "flex" }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ marginRight: "5px" }}
                  >
                    link
                  </span>{" "}
                  <strong style={{ marginRight: "5px" }}>Comissionadas:</strong>
                  {info[0].totalESLs - info[0].notCommissionedESLs} ESLs
                </p>
              </MDBRow>
              <MDBRow>
                <p
                  className="d-flex align-items-center"
                  style={{ display: "flex" }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ marginRight: "5px" }}
                  >
                    link_off
                  </span>{" "}
                  <strong style={{ marginRight: "5px" }}>
                    Não comissionadas:
                  </strong>
                  {info[0].notCommissionedESLs} ESLs
                </p>
              </MDBRow>
              <MDBRow>
                <p
                  className="d-flex align-items-center"
                  style={{ display: "flex" }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ marginRight: "5px" }}
                  >
                    heart_broken
                  </span>{" "}
                  <strong style={{ marginRight: "5px" }}>Manutenção:</strong>{" "}
                  {info[0].isntValidESLs} ESLs
                </p>
              </MDBRow>
            </SectionSquare>
          </MDBCol>
            ))*/}
      </MDBRow>
    </Container>
  );
}

export default StoreMapCorredores;

import styled from "styled-components";

export const Container = styled.div`
  flex-direction: column;
  align-items: flex-start;
  padding: 2% 5%;

  h3 {
    font-weight: bold;
  }
`;

export const Infos = styled.div`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 2%;

  h4 {
    font-weight: bold;
    margin-top: 1%;
  }

  h5,
  h6 {
    margin-top: 1%;
  }
`;

export const ESLsSquare = styled.div`
  background-color: white;
  width: 100%;
  justify-content: center;
  border-radius: 5px;
  flex-direction: column;
  display: inline-flex;
  margin: 2%;
  padding: 2%;
  min-height: 180px;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 3px 2px;

  h5,
  span {
    font-weight: bold;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

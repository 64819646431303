import styled from "styled-components";

export const Container = styled.div`
  padding: 2% 5%;

  h3 {
    font-weight: bold;
  }
`;

export const SectionSquare = styled.div`
  width: 100%;
  border-radius: 5px;
  color: black;
  margin: 10% 2% 2% 0%;
  padding: 5%;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 3px 2px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

// Home.js
import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import moment from "moment";
import { VictoryPie, VictoryBar, VictoryChart, VictoryAxis } from "victory";
import api from "../../services/api";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const Home = () => {
  const location = useLocation();

  console.log("[HOME] location: ", location);

  const today = new Intl.DateTimeFormat("en-US", {
    timeZone: "America/Sao_Paulo",
  }).format(new Date());

  const colors = {
    success: "#41A017",
    error: "#B42A27",
    on_going: "#C9A21B",
  };

  const [percentage, setPercentage] = useState({
    success: 0,
    error: 0,
    on_going: 0,
    total: 0,
    qtdTransactions: 0,
  });
  const [products, setProducts] = useState([]);

  function handleClick(data) {
    if (data && data.key && data.key === "pie-data-1") console.log(data);
  }

  function addTransparency(color) {
    if (color.startsWith("#")) {
      // Converte cor hexadecimal para RGBA
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      return `rgba(${r}, ${g}, ${b}, 0.9)`;
    }
    // Se a cor já estiver em formato RGB/RGBA, apenas ajusta a opacidade
    return color.replace(/[\d.]+\)$/g, "0.9)");
  }

  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const codeParam = params.get("code");

    localStorage.setItem("codePing", codeParam);
    localStorage.setItem("accessToken", codeParam);

    console.log("codePing: ", codeParam);
    console.log("accessToken: ", codeParam);

    let isMounted = true;
    async function getData() {
      try {
        const response = await api.get(
          `productUpdate/numbers?clientId=${localStorage.getItem(
            "client_id"
          )}&date=${moment.utc(today).format("YYYY-MM-DD")}`
        );

        setPercentage({
          error:
            response.data.total > 0
              ? (response.data.H * 100) / response.data.total
              : 0,
          on_going:
            response.data.total > 0
              ? (response.data.P * 100) / response.data.total
              : 0,
          success:
            response.data.total > 0
              ? (response.data.K * 100) / response.data.total
              : 0,
          total: response.data.total,
          qtdTransactions: response.data.qtdTransactions,
        });

        if (isMounted) setTimeout(getData, 5000);
      } catch (error) {
        toast.error("Não foi possível recuperar as atualizações.");
      }
    }

    async function getProducts() {
      try {
        const client_id = localStorage.getItem("client_id");
        const response = await api.get(
          `/productUpdate/mostTransactions/${client_id}`
        );

        const aux = [];

        response.data.map((item) =>
          aux.push({
            name: item.productTitle,
            quantity: item.count,
          })
        );

        aux.sort((a, b) => a.quantity < b.quantity).reverse();
        setProducts(aux);
      } catch (error) {
        console.log(error);
      }
    }
    getData();
    getProducts();

    return () => {
      isMounted = false;
    };
  }, [location.search, today]);

  return (
    <Container>
      <h3>
        Atualizações de preços do dia ({moment.utc(today).format("DD/MM/YYYY")})
      </h3>

      <div id="charts">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyItems: "flex-start",
            marginTop: 30,
            maxHeight: 530,
          }}
        >
          <h5 style={{ marginBottom: 5, alignSelf: "flex-start" }}>
            Atualizações de ESLs na loja
          </h5>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 50,
              alignItems: "center",
              marginTop: 20,
              marginBottom: "-10%",
            }}
          >
            <span
              style={{
                marginBottom: 10,
                display: "flex",
                alignItems: "center",
                fontSize: "black",
                fontWeight: "bolder",
              }}
            >
              <span
                style={{ color: colors.success, fontSize: 20, marginRight: 10 }}
              >
                •
              </span>{" "}
              Sucesso
            </span>
            <span
              style={{
                marginBottom: 10,
                display: "flex",
                alignItems: "center",
                fontWeight: "bolder",
              }}
            >
              <span
                style={{ color: colors.error, fontSize: 20, marginRight: 10 }}
              >
                •
              </span>{" "}
              Erro
            </span>
            <span
              style={{
                marginBottom: 10,
                display: "flex",
                alignItems: "center",
                fontSize: "black",
                fontWeight: "bolder",
              }}
            >
              <span
                style={{
                  color: colors.on_going,
                  fontSize: 20,
                  marginRight: 10,
                }}
              >
                •
              </span>{" "}
              Em andamento
            </span>
          </div>

          {percentage.total > 0 ? (
            <VictoryPie
              labelRadius={({ innerRadius, radius }) =>
                (radius + innerRadius + 30) / 3
              }
              colorScale={[colors.success, colors.error, colors.on_going]}
              width={300}
              height={250}
              style={{
                labels: {
                  fill: "white",
                  fontSize: 8,
                  fontWeight: "bold",
                  alignSelf: "center",
                },
                data: { strokeWidth: 3, stroke: "#fff" },
              }}
              data={[
                {
                  x: percentage.success > 0 ? `${percentage.success}%` : null,
                  y:
                    percentage.success < 10 && percentage.success !== 0
                      ? 10
                      : percentage.success,
                },
                {
                  x: percentage.error > 0 ? `${percentage.error}%` : null,
                  y:
                    percentage.error < 10 && percentage.error !== 0
                      ? 10
                      : percentage.error,
                },
                {
                  x: percentage.on_going > 0 ? `${percentage.on_going}%` : null,
                  y:
                    percentage.on_going < 10 && percentage.on_going !== 0
                      ? 10
                      : percentage.on_going,
                },
              ]}
              events={[
                {
                  target: "data",
                  eventHandlers: {
                    onMouseOver: () => {
                      return [
                        {
                          target: "data",
                          mutation: (props) => {
                            const newFill = addTransparency(props.style.fill);
                            return {
                              style: Object.assign({}, props.style, {
                                fill: newFill,
                                cursor:
                                  props.index && props.index === 1
                                    ? "pointer"
                                    : undefined,
                              }),
                            };
                          },
                        },
                      ];
                    },
                    onMouseOut: () => {
                      return [
                        {
                          target: "data",
                          mutation: () => null, // Retorna ao estilo original
                        },
                        {
                          target: "labels",
                          mutation: () => null, // Retorna ao estilo original
                        },
                      ];
                    },
                    onClick: (event, props) => {
                      handleClick(props);
                      return [];
                    },
                  },
                },
                {
                  target: "labels",
                  eventHandlers: {
                    onMouseOver: () => {
                      return [
                        {
                          target: "data",
                          mutation: (props) => {
                            const newFill = addTransparency(props.style.fill);
                            return {
                              style: Object.assign({}, props.style, {
                                fill: newFill,
                                cursor:
                                  props.index && props.index === 1
                                    ? "pointer"
                                    : undefined,
                              }),
                            };
                          },
                        },
                      ];
                    },
                    onMouseOut: () => {
                      return [
                        {
                          target: "data",
                          mutation: () => null, // Retorna ao estilo original
                        },
                        {
                          target: "labels",
                          mutation: () => null, // Retorna ao estilo original
                        },
                      ];
                    },
                    onClick: (event, props) => {
                      handleClick(props);
                      return [];
                    },
                  },
                },
              ]}
            />
          ) : (
            <VictoryPie
              labelRadius={({ innerRadius, radius }) =>
                (radius + innerRadius - 90) / 2
              }
              colorScale={["#bbb"]}
              width={300}
              height={250}
              style={{
                labels: {
                  fill: "white",
                  fontSize: 12,
                  fontWeight: "bold",
                  alignSelf: "center",
                },
                data: { strokeWidth: 3, stroke: "#fff" },
              }}
              data={[
                {
                  x: "Sem atualizações",
                  y: 100,
                },
              ]}
            />
          )}
        </div>
        <div
          id="chartBarDiv"
          style={{ display: "flex", flexDirection: "column", maxHeight: 530 }}
        >
          <h5 style={{ marginBottom: "-10%", alignSelf: "flex-start" }}>
            Produtos mais atualizados do mês
          </h5>
          {products.length > 0 && (
            <VictoryChart domainPadding={{ x: 5 }} height={200} width={180}>
              <VictoryAxis
                style={{
                  tickLabels: { fontSize: 5 },
                }}
              />
              <VictoryBar
                horizontal={true}
                labels={({ datum }) => `${datum.y}x`}
                style={{ data: { fill: "#41A017" }, labels: { fontSize: 6 } }}
                data={products.map((item) => {
                  return {
                    x:
                      item.name.length > 18
                        ? `${item.name.substring(0, 18)}\n${item.name.substring(
                            18
                          )}`
                        : item.name,
                    y: item.quantity,
                  };
                })}
              />
            </VictoryChart>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Home;

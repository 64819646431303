import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

/*api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers["authorization"] = `${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);*/

/*api.interceptors.response.use(
  (response) => response,
  (error) => {
    //if (error.response.data.message === "Token inválido ou expirado") {
    //  localStorage.removeItem("token");
    //  window.location.href = "/login";
    //}
    return Promise.reject(error);
  }
);*/

export default api;

import styled, { keyframes } from "styled-components";
import { IoIosSync } from "react-icons/io";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
`;

export const Button = styled.div`
  button {
    background: #78be20;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(211, 47, 47, 0.24);
    color: #ffffff;
    border: 0;
    height: 50px;
    width: 47%;
    margin-top: 1%;
  }
`;

export const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const PendingIcon = styled(IoIosSync)`
  animation: ${spinAnimation} 1s infinite linear;
  font-size: 1.5rem; /* Tamanho personalizado */
`;

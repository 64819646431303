import styled from "styled-components";

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

export const UploadInput = styled.input`
  display: none;
`;

export const UploadButton = styled.label`
  background-color: #f0f0f0;
  color: #333;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ddd;
  }
`;

import styled, { keyframes } from "styled-components";
import {
  IoIosSync,
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from "react-icons/io";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2% 5%;
  

  h3,
  th {
    font-weight: bold;
  }
`;

export const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const PendingIcon = styled(IoIosSync)`
  animation: ${spinAnimation} 1s infinite linear;
  font-size: 1.5rem; /* Tamanho personalizado */
`;

export const OKIcon = styled(IoIosCheckmarkCircleOutline)`
  font-size: 1.5rem; /* Tamanho personalizado */
`;

export const ErrorIcon = styled(IoIosCloseCircleOutline)`
  font-size: 1.5rem; /* Tamanho personalizado */
`;

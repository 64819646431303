import React, { useState } from "react";
import { Container } from "./styles";

function BlockedInput(props) {
  return (
    <Container>
      <input
        type="text"
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        style={{ textAlign: "right" }}
      />
    </Container>
  );
}

export default BlockedInput;

import React, { useState } from "react";
import { Container } from "./styles";
import { MDBIcon } from "mdb-react-ui-kit";
import ReactInputMask from "react-input-mask";

function InputMak(props) {
  const [showPassword, setShowPassword] = useState(false);

  const inputType =
    props.type === "password" ? (showPassword ? "text" : "password") : "text";

  return (
    <Container>
      <ReactInputMask
        type={inputType}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        mask={props.mask}
      />
      <button
          type="button"
          className="password-toggle"
        />
    </Container>
  );
}

export default InputMak;

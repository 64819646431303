import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2% 5%;

  h3 {
    font-weight: bold;
  }

  #charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin-top: 30;
  }

  #chartBarDiv {
    margin-top: 30px;
  }

  @media screen and (max-width: 1024px) {
    #charts {
      grid-template-columns: 1fr;
    }

    #chartBarDiv {
      margin-top: -10%;
    }
  }
`;

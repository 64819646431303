import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Container } from "./styles";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import api from "../../services/api";
import {
  IoIosCheckmarkCircle,
  IoIosCloseCircle,
  IoIosHelpCircle,
} from "react-icons/io";
import { toast } from "react-toastify";
import moment from "moment";

const EslsUpdateFilter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { transactionId } = useParams();
  const today = new Intl.DateTimeFormat("en-US", {
    timeZone: "America/Sao_Paulo",
  }).format(new Date());

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true
    async function getData() {
      try {
        const response = await api.get(
          `/productUpdate/errors?clientId=${localStorage.getItem(
            "client_id"
          )}&date=${moment.utc(today).format("YYYY-MM-DD")}`
        );

        const esls_aux = [];

        response.data.forEach((item) => {
            const esls = item.esls.map(esl => ({
                mac: esl.mac,
                status: esl.update_status === "H"
                ? "Erro"
                : item.update_status === "P"
                ? "Em andamento"
                : "Sucesso",
            }))
            esls_aux.push({
                transactionId: item.transactionId,
                product_title: item.product.product_title,
                esls
            });
        });

        setProducts(esls_aux);
        console.log(response.data)

        if(isMounted) setTimeout(getData, 5000);
      } catch (error) {
        toast.error("Erro ao recuperar ESLs dessa transação");
        console.log(error)
      }
    }

    getData();

    return () => {
      isMounted = false
    };
  }, [loading]);

  function handleToDetailsESL(mac) {
    navigate(`/details/${mac}`);
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: 16,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 16,
            }}
          >
            <h3>Etiquetas com erros na atualização</h3>
          </div>
        </div>
        {/* <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <span>Filtros:</span>
          <button onClick={() => update('success')} style={{ paddingLeft: 8, paddingRight: 8, borderWidth: success ? 2 : 1, borderColor: success ? 'rgb(120, 190, 32)' : 'black' }}>Sucessos</button>
          <button onClick={() => update('in_progress')} style={{ paddingLeft: 8, paddingRight: 8, borderWidth: in_progress ? 2 : 1, borderColor: in_progress ? 'rgb(120, 190, 32)' : 'black' }}>Em andamento</button>
          <button onClick={() => update('failed')} style={{ paddingLeft: 8, paddingRight: 8, borderWidth: failed ? 2 : 1, borderColor: failed ? 'rgb(120, 190, 32)' : 'black' }}>Erros</button>
        </div> */}
      </div>

      <MDBTable align="start" hover>
        <MDBTableHead>
          <tr>
            <th>Transaction Id</th>
            <th>Nome do Produto</th>
            <th>MAC Etiqueta</th>
            <th>Status</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {products.map((product) => { 
            return product.esls.map(esl => (
                <tr>
                  <td
                    onClick={() => handleToDetailsESL(esl.mac)}
                    style={{ cursor: "pointer" }}
                  >
                    {product.transactionId}
                  </td>
                  <td
                    onClick={() => handleToDetailsESL(esl.mac)}
                    style={{ cursor: "pointer" }}
                  >
                    {product.product_title}
                  </td>
                  <td
                    onClick={() => handleToDetailsESL(esl.mac)}
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      style={{
                        marginRight: 4,
                        color:
                          esl.status === "Sucesso"
                            ? "#41A017"
                            : esl.status === "Erro"
                            ? "#B42A27"
                            : "#C9A21B",
                      }}
                    >
                      {esl.mac}
                    </span>
                    <span
                      style={{
                        color:
                          esl.status === "Sucesso"
                            ? "#41A017"
                            : esl.status === "Erro"
                            ? "#B42A27"
                            : "#C9A21B",
                      }}
                    >
                      {esl.status === "Sucesso" ? (
                        <IoIosCheckmarkCircle size={20} />
                      ) : esl.status === "Erro" ? (
                        <IoIosCloseCircle size={20} />
                      ) : (
                        <IoIosHelpCircle size={20} />
                      )}
                    </span>
                  </td>
                </tr>
              ))
            
            })}
        </MDBTableBody>
      </MDBTable>
    </Container>
  );
};

export default EslsUpdateFilter;

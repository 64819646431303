import React, { useState, useEffect } from "react";
import { Container, SubmitButton, Title } from "./styles";
import Uploader from "../../components/Uploader/Uploader";
import api from "../../services/api";
import * as XLSX from "xlsx";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MDBRow, MDBProgress, MDBProgressBar, MDBCol } from "mdb-react-ui-kit";

import Input from "../../components/Input/Input";
import ButtonGreen from "../../components/ButtonGreen/ButtonGreen";

const InsertGateway = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const leroyCode = localStorage.getItem("code");
  const [successCount, setSuccessCount] = useState(0);
  const [errors, setErrors] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const [storeCode, setStoreCode] = useState("");
  const [mac, setMac] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        client_id: 1,
        store_code: storeCode,
        mac: mac,
        size: "S",
        firmware_name: "minew",
      };

      api
        .post(`/insert/esl`, data)
        .then((response) => {
          toast.success(`MAC ${response.data.mac} inserido com sucesso!`);
          setMac("");
          setStoreCode("");
        })
        .catch((error) => {
          toast.error("Erro ao inserir Gateway.");
        });
    } catch (err) {
      if (err instanceof Error) {
        console.log("err.message", err.message);
      }
    }
  };

  useEffect(() => {
    if (progress === 100) {
      setProgress(100);
    }

    if (isProcessing && progress === 100) {
      setIsProcessing(false);

      toast.success(`MACs inseridos com sucesso!`);
      if (errors.length > 0) {
        errors.forEach((error) => {
          toast.error(`MACs ${error.mac} duplicado!`);
        });
      }
    }
  }, [isProcessing, progress, successCount, errors]);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  const handleFileLoad = (event) => {
    const fileData = event.target.result;
    const workbook = XLSX.read(fileData, { type: "array" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    const promises = [];
    const newErrors = [];

    setProgress(0);
    setSuccessCount(0);
    setErrors([]);
    setIsProcessing(true);

    jsonData.forEach((row, index) => {
      const values = Object.values(row);

      const data = {
        client_id: 1,
        store_code: values[0],
        mac: values[1],
        size: "S",
        firmware_name: "minew",
      };

      const promise = new Promise((resolve) => {
        api
          .post(`/insert/esl`, data)
          .then(() => {
            resolve({ success: true, mac: data.mac });
          })
          .catch((error) => {
            let errorMessage = "Erro ao inserir Gateway";
            if (
              error.response &&
              error.response.data &&
              error.response.headers["content-type"].includes(
                "application/json"
              )
            ) {
              try {
                errorMessage = JSON.parse(error.response.data).message;
              } catch (parseError) {
                console.error(
                  "Erro ao fazer o parse da resposta JSON",
                  parseError
                );
              }
            }
            newErrors.push({ mac: data.mac, error: errorMessage });
            resolve({ success: false, mac: data.mac, error: errorMessage });
          })
          .finally(() => {
            const newProgress = Math.floor(
              ((index + 1) / jsonData.length) * 100
            );
            setProgress(newProgress);
          });
      });

      promises.push(promise);
    });

    Promise.all(promises)
      .then((results) => {
        const count = results.filter((result) => result.success).length;
        setSuccessCount(count);
        setErrors(newErrors);
        setProgress(100);
      })
      .catch((error) => {
        toast.error("Erro ao inserir Gateways.");
      });
  };

  const handleSendButtonClick = () => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = handleFileLoad;
      reader.readAsArrayBuffer(selectedFile);
    }
  };

  return (
    <>
      <ToastContainer />
      <Title className="text-start">
        <h3>Inserir novos Gateways</h3>
        {/*<MDBRow>
          <MDBProgress height="20">
            <MDBProgressBar width={progress} striped animated>
              {parseInt(progress)}%
            </MDBProgressBar>
          </MDBProgress>
  </MDBRow>*/}
      </Title>

      <Container>
        <form onSubmit={handleSubmit}>
          <Input
            type="text"
            autoComplete="storeCode"
            name="Códido da loja"
            value={storeCode || ""}
            placeholder="Código da loja"
            onChange={(event) => setStoreCode(event.target.value)}
          />
          <br />
          <Input
            type="text"
            autoComplete="mac"
            name="mac"
            value={mac || ""}
            placeholder="Mac"
            onChange={(event) => setMac(event.target.value)}
          />
          <br />
          <MDBRow>
            <MDBCol>
              <ButtonGreen type="submit" label="Inserir" />
            </MDBCol>
          </MDBRow>
        </form>

        <br />

        <MDBRow>
          <Uploader onFileSelect={handleFileSelect} />
        </MDBRow>
        <MDBRow>
          <SubmitButton
            onClick={handleSendButtonClick}
            disabled={!selectedFile}
          >
            Enviar
          </SubmitButton>
        </MDBRow>
      </Container>
    </>
  );
};

export default InsertGateway;

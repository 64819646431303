import React from "react";
import { Container } from "./styles";

function Select(props) {
  const handleChange = (event) => {
    const { value } = event.target;
    props.onChange(value);
  };

  return (
    <Container>
      <select
        key={props.index}
        value={props.selection || ""}
        onChange={handleChange}
      >
        <option value="">{props.label}</option>
        {props.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </Container>
  );
}

export default Select;

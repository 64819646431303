import { useState } from "react";
import api from "../services/api";
import { useNavigate } from "react-router-dom";

const useAuth = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  const login = async (LDAP, password) => {
    try {
      const response = await api.post("/login", { LDAP, password });
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("code", response.data.code);
      localStorage.setItem("name", response.data.name);
      localStorage.setItem("client_id", response.data.client_id);
      localStorage.setItem("store_code", response.data.store_code);
      setAuthenticated(true);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("code");
    localStorage.removeItem("name");
    localStorage.removeItem("storeCode");
    setAuthenticated(false);
    navigate("/login");
  };

  return { authenticated, login, logout };
};

export default useAuth;

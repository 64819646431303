import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Infos } from "./styles";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

import api from "../../services/api";
import Input from "../../components/Input/Input";
import ButtonGrey from "../../components/ButtonGrey/ButtonGrey";
import ButtonGreen from "../../components/ButtonGreen/ButtonGreen";

function SearchProduct() {
  const [gtin, setGtin] = useState("");
  const [locations, setLocations] = useState([]);

  function handleSearchGTIN() {
    api
      .get(`/search/location/${gtin}`)
      .then((response) => {
        setLocations(response.data);
      })
      .catch(() => {
        setLocations([]);
      });
  }

  return (
    <Container>
      <h3>Produtos</h3>

      <Infos>
        <MDBRow style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <MDBCol md="2" className="d-flex align-items-center">
            <h5>Buscar produto: </h5>
          </MDBCol>
          <MDBCol md="7">
            <Input
              type="text"
              autoComplete="gtin"
              name="gtin"
              placeholder="Digite o GTIN do produto"
              value={gtin}
              onChange={(e) => setGtin(e.target.value)}
            />
          </MDBCol>
          <MDBCol md="3">
            <ButtonGreen
              type="submit"
              onClick={handleSearchGTIN}
              label="Pesquisar"
            />
          </MDBCol>
        </MDBRow>

        <MDBTable align="start" hover>
          <MDBTableHead>
            <tr>
              <th scope="col">SGLN</th>
              <th scope="col">Loja</th>
              <th scope="col">Seção</th>
              <th scope="col">Corredor</th>
              <th scope="col">Módulo</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {locations.length === 0 ? (
              <tr>
                <td colSpan="5">
                  <p className="text-center" style={{ marginTop: "1%" }}>
                    Nenhuma localização encontrada.
                  </p>
                </td>
              </tr>
            ) : (
              locations.map((location) => (
                <tr key={location.sgln} className="table-light">
                  <td>{location.sgln}</td>
                  <td>{location.store_code}</td>
                  <td>{location.depth_0}</td>
                  <td>{location.depth_1}</td>
                  <td>{location.depth_2}</td>
                </tr>
              ))
            )}
          </MDBTableBody>
        </MDBTable>
      </Infos>
    </Container>
  );
}

export default SearchProduct;

// Routes.js
import { Route, Routes as RoutesDom, useLocation } from "react-router-dom";

import Home from "./pages/Home/Home";
//import Login from "./pages/Login/Login";
import Stores from "./pages/Stores/Stores";
import ProductUpdate from "./pages/ProductUpdate/ProductUpdate";
import ESLsUpdate from "./pages/ESLsUpdate/ESLsUpdate";
import Commissions from "./pages/Commissions/Commissions";
import Installs from "./pages/Installs/Installs";
import StoreMapSecoes from "./pages/StoreMapSecoes/StoreMapSecoes";
import StoreMapCorredores from "./pages/StoreMapCorredores/StoreMapCorredores";
import StoreMapModulos from "./pages/StoreMapModulos/StoreMapModulos";
import ESLs from "./pages/ESLs/ESLs";
import ESLDetails from "./pages/ESLDetails/ESLDetails";
import InsertESL from "./pages/InsertESL/InsertESL";
import InsertGateway from "./pages/InsertGateway/InsertGateway";
import InsertSGLN from "./pages/InsertSGLN/InsertSGLN";
import NotFound from "./pages/NotFound";
import Navbar from "./components/Navbar/Navbar";
import Register from "./pages/Register/Register";
import InserirAVRO from "./pages/InserirAVRO/InserirAVRO";
import PrintQRCode from "./pages/PrintQRCode/PrintQRCode";
import SearchLocalization from "./pages/SearchLocalization/SearchLocalization";
import SearchProduct from "./pages/SearchProduct/SearchProduct";
import ProductUpdateFilter from "./pages/ProductUpdateFilter/ProductUpdateFilter";
import EslsUpdateFilter from "./pages/ESLsUpdateFilter/ESLsUpdateFilter";

const Routes = () => {
  const location = useLocation();

  const isRegisterPage = location.pathname === "/register";

  const codePing = localStorage.getItem("codePing");
  const accessToken = localStorage.getItem("accessToken");

  if (!codePing || !accessToken) {
    window.location.href = `${process.env.REACT_APP_PING_AUTHORIZATION_URL}?client_id=${process.env.REACT_APP_PING_CLIENT_ID}&response_type=${process.env.REACT_APP_PING_RESPONSE_TYPE}&scope=${process.env.REACT_APP_PING_SCOPE}&redirect_uri=${process.env.REACT_APP_PING_REDIRECT_URI}`;
  }

  return (
    <>
      {!isRegisterPage && <Navbar />}
      {accessToken ? (
        <RoutesDom>
          <Route index path="home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="stores" element={<Stores />} />
          <Route path="productUpdate" element={<ProductUpdate />} />
          <Route
            path="productUpdateFilter/filter"
            element={<ProductUpdateFilter />}
          />
          <Route path="eslUpdate/filter/error" element={<EslsUpdateFilter />} />
          <Route path="eslUpdate/:transactionId" element={<ESLsUpdate />} />
          <Route path="installs" element={<Installs />} />
          <Route path="commissions" element={<Commissions />} />
          <Route path="storemapsecoes" element={<StoreMapSecoes />} />
          <Route path="storemapcorredores" element={<StoreMapCorredores />} />
          <Route path="storemapmodulos" element={<StoreMapModulos />} />
          <Route path="esls" element={<ESLs />} />
          <Route path="details/:mac" element={<ESLDetails />} />
          <Route path="insertESL" element={<InsertESL />} />
          <Route path="insertGateway" element={<InsertGateway />} />
          <Route path="insertSGLN" element={<InsertSGLN />} />
          <Route path="printQRCode" element={<PrintQRCode />} />
          <Route path="inserirAVRO" element={<InserirAVRO />} />
          <Route path="searchLocalization" element={<SearchLocalization />} />
          <Route path="searchProduct" element={<SearchProduct />} />
          <Route path="*" element={<NotFound />} />
        </RoutesDom>
      ) : (
        <RoutesDom>
          {/*<Route path="login" element={<Login />} />*/}
          <Route path="register" element={<Register />} />
          <Route index path="home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </RoutesDom>
      )}
    </>
  );
};

export default Routes;

// App.js
import "./App.css";

import React, { useEffect } from "react";
import Routes from "./routes";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";

const socket = io(process.env.REACT_APP_SOCKET_URL);

function App() {
  // const [showToast, setShowToast] = useState(true)
  useEffect(() => {
    socket.on("productUpdate", (JSONPriceUpdate) => {
      const token = localStorage.getItem("token");
      // console.log('nao sei')

      if (token) {
        toast.info("Atualizações de preço em andamento!");
        // setShowToast(false)
        // setTimeout(() => setShowToast(true), 10000)
      }
    });

    socket.on("sucessPriceUpdate", (JSONPriceUpdate) => {
      /*localStorage.removeItem(
        `updateGTIN-${JSONPriceUpdate.salesPackagingBarcodeNumber}`
      );*/
      toast.success("Atualizações de preço finalizadas com sucesso!");
    });

    socket.on("errorPriceUpdate", (JSONPriceUpdate) => {
      toast.error("Atualizações de preço finalizadas erro!");
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <Routes />
    </div>
  );
}

export default App;

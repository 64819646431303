import styled from "styled-components";

export const Container = styled.button`
  background-color: ${(props) => (props.active ? "white" : "#00263a")};
  border: 2px solid ${(props) => (props.active ? "white" : "#00263a")};
  color: ${(props) => (props.active ? "#00263a" : "white")};
  width: 32px;
  height: 32px;
  border-radius: 7px;
  border-color: ${(props) => (props.active ? "#00263a" : "white")};
  display: inline-block;
  margin-inline-start: 8px;
  margin-top: 3px;
`;

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 1rem;
  position: relative;

  input {
    padding: 0.5rem;
    border: 1px solid #cbccd1; /* Adicione a cor e a largura da borda aqui */
    border-radius: 5px; /* Adicione o raio da borda aqui */
    font-size: 1rem;
    background-color: #fff; /* Adicione a cor de fundo aqui */
    outline: none; /* Remova a borda ao receber foco */
  }

  .password-toggle {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    cursor: pointer;
  }
`;

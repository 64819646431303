import React, { useState } from "react";
import { Container } from "./styles";
import { MDBIcon } from "mdb-react-ui-kit";

function Input(props) {
  const [showPassword, setShowPassword] = useState(false);

  function handleTogglePassword() {
    setShowPassword(!showPassword);
  }

  const inputType =
    props.type === "password" ? (showPassword ? "text" : "password") : "text";

  return (
    <Container>
      <input
        type={inputType}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <button
          type="button"
          className="password-toggle"
        />
    </Container>
  );
}

export default Input;
